import React, { useState } from "react";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import ProductToolbar from "./ProductToolbar.js";
import ProductResults from "./ProductResults.js";
import FontToolbar from "./FontToolbar.js";
import FontResults from './FontResults.js'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";

const stores = [
  {
    value: "l1",
    label: "Loja 1",
  },
  {
    value: "l2",
    label: "Loja 2",
  },
  {
    value: "l3",
    label: "Loja 3",
  },
];

const products = [
  {
    value: "p1",
    label: "Produto 1",
  },
  {
    value: "p2",
    label: "Produto 2",
  },
  {
    value: "p3",
    label: "Produto",
  },
];

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    firstName: "Katarina",
    lastName: "Smith",
    email: "demo@devias.io",
    phone: "",
    state: "Alabama",
    country: "USA",
  });

  // const [customers] = useState(data);
  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [selectedFontIds, setSelectedFontIds] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClick = async () => {
    try {
      for (let i = 0; i < selectedFontIds.length; i++) {
        for (let j = 0; j < selectedProductIds.length; j++) {
          const data_to_post = {
            "product_id": selectedProductIds[j],
            "font_id":  selectedFontIds[i]
          }
          console.log("asdasdasd", data_to_post)
          const promise = await axios.post("/api/associations/", data_to_post);
          console.log("resposta", promise)
        }
      }
      alert("Associações Criadas!")
    } catch (error) {
      alert("ERRO: Uma ou mais associações feitas já existe!")
    }

    // reseta os states
    setFonts([])
    setProducts([])
    setSelectedFontIds([])
    setSelectedProductIds([])
  }


  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adicionar nova associação entre produto e fonte"
          title="Associação"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FontToolbar
                fonts={fonts}
                setFonts={setFonts}
                selectedFontIds={selectedFontIds}
              ></FontToolbar>
              <FontResults
                fonts={fonts}
                selectedFontIds={selectedFontIds}
                setSelectedFontIds={setSelectedFontIds}
              ></FontResults>
              {/* <TextField
                fullWidth
                label="Selecionar Produto"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {products.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
            </Grid>
            <Grid item md={6} xs={12}>
              <ProductToolbar
                products={products}
                setProducts={setProducts}
                selectedProductIds={selectedProductIds}
              ></ProductToolbar>
              <ProductResults
                products={products}
                selectedProductIds={selectedProductIds}
                setSelectedProductIds={setSelectedProductIds}
              ></ProductResults>
              {/* <TextField
                fullWidth
                label="Selecionar Estabelecimento"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {stores.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Criar Associação
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const states = [
  {
    value: null,
    label: 'Selecione o Estado'
  },
  {
    value: 'mg',
    label: 'Minas Gerais'
  },
  {
    value: 'rj',
    label: 'Rio de Janeiro'
  },
  {
    value: 'sp',
    label: 'São Paulo'
  }
];


const ModalForm = ({ className, fontData, setFontData, font_id, ...rest }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    // aqui é necessário usar o mesmo hook que é utilizado para carregar os dados
    // dessa forma, quando o usuario digita algo novo para atualizar, tudo fica de acordo
    setFontData({
      ...fontData,
      [event.target.name]: event.target.value
    });
  };

  const handleClick = async () =>{
    let data_to_post ={
      "name": fontData.name,
      "web_address": fontData.web_address,
      "address": fontData.address,
      "br_state": fontData.br_state,
      "cep": fontData.cep,
      "latitude": fontData.latitude,
      "longitude": fontData.longitude,
      "cnpj": fontData.cnpj
    }

    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      console.log("veja aqui a id", font_id)
      const promise = await axios.put("/api/fonts/"+font_id+"/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise)
      alert("Fonte atualizada com sucesso!")  
    //   window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
      <CardHeader
        subheader="Editar fonte"
        title="Fonte"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              helperText="Especifique o nome da fonte"
              label="Nome"
              name="name"
              onChange={handleChange}
              required
              value={fontData.name}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Endereço na Web"
              name="web_address"
              onChange={handleChange}
              required
              value={fontData.web_address}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              helperText="Endereço físico, caso exista"
              label="Endereço Físico"
              name="address"
              onChange={handleChange}
              required
              value={fontData.address}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Selecionar Estado"
              name="br_state"
              onChange={handleChange}
              required
              select
              SelectProps={{ native: true }}
              value={fontData.state}
              variant="outlined"
            >
              {states.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="CEP"
              name="cep"
              onChange={handleChange}
              required
              value={fontData.cep}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="CNPJ"
              name="cnpj"
              onChange={handleChange}
              // type="number"
              value={fontData.cnpj}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              onChange={handleChange}
              type="latitude"
              value={fontData.latitude}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              onChange={handleChange}
              type="number"
              value={fontData.longitude}
              variant="outlined"
            />
          </Grid>
          
        </Grid>
      </CardContent>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          Salvar
        </Button>
      </Box>
    </Card>
    </form>
  );
};

ModalForm.propTypes = {
  className: PropTypes.string
};

export default ModalForm;

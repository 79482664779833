import React, { useState, useEffect, useReducer } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import ProductsTable from "./ProductsTable";
import DescriptionCard from "./DescriptionCard";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LabellingView = () => {
  const classes = useStyles();
  const [description, setDescription] = useState({
    id: 0,
    content: "",
  });

  const [radioLabels, setRadioLabels] = useState([{}]);
  const [products, setProducts] = useState([{}]);
  const [judgeId, setJudgeId] = useState(-1)
  const [subitemName, setSubitemName] = useState("")

  const fetchData = async () => {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFToken";

    const username = localStorage.getItem("username");

    const all_judges = await axios.get("https://feedback-api.host27.adm.se.latin.dcc.ufmg.br/judges/")
    
    
    for (let i = 0; i < all_judges.data.length; i++) {
      const element = all_judges.data[i];

      const aux_username = username.substring(1, username.length-1);
      console.log(aux_username, element["username"])
      if(element["username"]===aux_username){
        console.log(element["id"])
        // judge_id = element["id"]
        setJudgeId(element["id"])
      }
    }

    console.log("ID DO CARA", judgeId)

    const promise_description = await axios.get(
      "https://feedback-api.host27.adm.se.latin.dcc.ufmg.br/descriptions/high-priority?judgeId="+judgeId
    );
    console.log("asdfasdfsadf", promise_description.data)
    setDescription({
      id: promise_description.data.id,
      content: promise_description.data.content,
    });
    // console.log("descrição bem aqui ", prodescription);

    // fazendo request dos produtos
    const promise_products = await axios.get(
      "https://feedback-api.host27.adm.se.latin.dcc.ufmg.br/descriptions/" +
        promise_description.data.id +
        "/products"
    );
    setProducts(promise_products.data);
    setSubitemName(promise_products.data[0].subitem)
    console.log("veja os produtos aqui", promise_products.data);
    console.log("Veja subitem", subitemName)

    let labelsAux = [];
    for (let i = 0; i < promise_products.data.length; i++) {
      const element = promise_products.data[i];
      labelsAux.push({ id: element.id, radioLabel: -1});
    }
    setRadioLabels(labelsAux);

    console.log("olha as labels", labelsAux);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const theUserLabelledEverything = () =>{
    let flag = true;
    for (let i = 0; i < radioLabels.length; i++) {
      if (radioLabels[i].radioLabel === -1) {
        flag = false;
      }
    }
    return  flag
  }


  const postRatings = async () =>{
    let dataToPost = []
    for (let i = 0; i < radioLabels.length; i++) {
      let labels = {
        "productId": radioLabels[i].id,
        "descriptionId": description.id,
        "judgeId":judgeId,
        "rating": radioLabels[i].radioLabel
      }
      if(radioLabels[i]["comment"]){
        labels["comment"] = radioLabels[i]["comment"]
      }
      dataToPost.push(labels)
      console.log(labels)
    }
    try {
      const promise = await axios.post("https://feedback-api.host27.adm.se.latin.dcc.ufmg.br/feedbacks/", dataToPost)
      console.log("veja a promessa", promise.status)
      console.log("vjea bem")
    } catch (error) {
      alert(error)
    }
  }

  const nextLabel = () => {
    if (theUserLabelledEverything()) {
      postRatings()
      alert("Avaliação realizada com sucesso!");
      window.location.reload(false);
    } else {
      alert("Não esqueça de avaliar todos os produtos!!");
    }
  };

  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        // height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box mb={3}>
            <DescriptionCard description={description} subitemName={subitemName}></DescriptionCard>
          </Box>

          <Box mb={3}>
            <ProductsTable
              products={products}
              radioLabels={radioLabels}
              setRadioLabels={setRadioLabels}
            />
          </Box>

          <Box mb={3}>
            <Button color="primary" variant="contained" onClick={nextLabel}>
              Próxima Avaliação
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LabellingView;

import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import ProductsTable from "./ProductsTable";
import DescriptionCard from "./DescriptionCard";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LabellingView = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        // height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box mb={6}>
            <Typography color="textPrimary" variant="h1">
              Para cada
              produto apresentado a seguir, avalie a sua compatibilidade com a descrição
              mostrada. Clique no botão abaixo para iniciar.
            </Typography>
          </Box>

          <Box mb={3}>
            <Button color="primary" variant="contained" component={RouterLink} to="/app/labelling/start">
              Iniciar Avaliação
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LabellingView;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LabellingRadioSelect from "./LabellingRadioSelect";
import { Button } from "@material-ui/core";
import OpenImageModal from "./OpenImageModal.js";
import axios from "axios";
import ImageCard from "./ImageCard.js";
import MetadaList from "./MetadataList.js"

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row({ key, row, radioLabels, setRadioLabels, ...rest }) {
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <ImageCard imageUrl={row.productImgUrl}></ImageCard>
        </TableCell>
        <TableCell align="right">
          <MetadaList metaData={row}></MetadaList>
          {/* {row.name}
          {row.placeName} {row.metadata} */}
        </TableCell>
        {/* <TableCell align="right">
          <OpenImageModal></OpenImageModal>
        </TableCell> */}
        <TableCell>
          <Box margin={1} mb={3}>
            <Typography variant="h4" gutterBottom component="div">
              O produto apresentado corresponde à descrição?
            </Typography>
            <LabellingRadioSelect
              id={row.id}
              row={row}
              radioLabels={radioLabels}
              setRadioLabels={setRadioLabels}
            ></LabellingRadioSelect>
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProductsTable({
  products,
  radioLabels,
  setRadioLabels,
  ...rest
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Imagem</TableCell>
            <TableCell align="left">Informações</TableCell>
            <TableCell>Avaliar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <Row
              key={product.id}
              row={product}
              radioLabels={radioLabels}
              setRadioLabels={setRadioLabels}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

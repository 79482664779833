import React, { useState } from "react";
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Profile from './Profile';
// import ProfileDetails from './ProfileDetails';
// import FontDetails from './FontDetails';
import ProductDetails from './Associations';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AssociationsView = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Produtos"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            <ProductDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AssociationsView;

import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import OpenImageModal from "./OpenImageModal.js";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export default function RadioButtonsGroup({
  id,
  row,
  radioLabels,
  setRadioLabels,
  ...rest
}) {
  const [value, setValue] = React.useState("female");
  const [feedbackText, setFeedbackText] = useState("");

  const [radioOpt1, setRadioOpt1] = useState(true);
  const [radioOpt0, setRadioOpt0] = useState(true);

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value);

    let aux_labels = [];
    for (let i = 0; i < radioLabels.length; i++) {
      if (id == radioLabels[i].id) {
        aux_labels.push({
          id: radioLabels[i].id,
          radioLabel: radioLabels[i].radioLabel,
          comment: feedbackText,
        });
      } else {
        aux_labels.push(radioLabels[i]);
      }
    }
    setRadioLabels(aux_labels);
    console.log("olha aqui atualizado", id, feedbackText, aux_labels);
  };

  const handleChange = (event) => {
    // gambiarra pra copiar o estado dos radiolabels
    setValue(event.target.value);
    if (Number(event.target.value) === 0) {
      setRadioOpt1(true);
      setRadioOpt0(false);
      // alert("0")
    } else if (Number(event.target.value) === 5) {
      setRadioOpt1(false);
      setRadioOpt0(true);
      // alert("1")
    } else if(Number(event.target.value) === 6){
      setRadioOpt1(true);
      setRadioOpt0(true);
      // alert("2")
    }

    let aux_labels = [];
    for (let i = 0; i < radioLabels.length; i++) {
      if (id == radioLabels[i].id) {
        aux_labels.push({
          id: radioLabels[i].id,
          radioLabel: Number(event.target.value),
        });
      } else {
        aux_labels.push(radioLabels[i]);
      }
    }
    console.log(aux_labels)
    setRadioLabels(aux_labels);
  };

  return (
    <Box margin={1} mb={3}>
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">Gender</FormLabel> */}
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label="Não corresponde"
          />
          {/* <div> */}
            {/* {radioOpt0 ? null : ( */}
              {/* <RadioGroup size="small"> */}
              <FormControlLabel
                  size="small"
                  value="1"
                  color="green"
                  disabled={radioOpt0}
                  control={<Radio />}
                  style={{ paddingLeft: '20px' }}
                  label="Produto fora do escopo da cesta atual"
                />
                <FormControlLabel
                  size="small"
                  value="2"
                  disabled={radioOpt0}
                  control={<Radio />}
                  style={{ paddingLeft: '20px' }}
                  label="Produto pode ser aproveitado para outro subitem"
                />
                <FormControlLabel
                  size="small"
                  value="3"
                  disabled={radioOpt0}
                  control={<Radio />}
                  style={{ paddingLeft: '20px' }}
                  label="Potencial para criação de novo produto dentro do mesmo subitem"
                />
                <FormControlLabel
                  color={green[400]}
                  size="small"
                  value="4"
                  disabled={radioOpt0}
                  control={<Radio />}
                  style={{ paddingLeft: '20px' }}
                  label="Descrição atribuível a outro produto pertencente ao mesmo subitem"
                />
                
                
                
              {/* </RadioGroup> */}
            {/* )} */}
          {/* </div> */}

          <FormControlLabel
            value="5"
            control={<Radio />}
            label="Corresponde parcialmente (Possível substituto para o produto da busca)"
          />
          {/* <div> */}
            {/* {radioOpt1 ? null : ( */}
              {/* <RadioGroup> */}
                {/* <FormControlLabel
                  size="small"
                  value="6"
                  disabled={radioOpt1}
                  control={<Radio />}
                  label="Possível substituto para o produto da busca"
                  style={{ paddingLeft: '20px' }}
                /> */}
              {/* </RadioGroup> */}
            {/* )} */}
          {/* </div> */}
          <FormControlLabel
            value="6"
            control={<Radio />}
            label="Corresponde perfeitamente"
          />
        </RadioGroup>
      </FormControl>
      <Box margin={1} mb={3}>
        <TextField
          onChange={handleTextChange}
          id="outlined-basic"
          label="Feedback (opcional)"
          variant="outlined"
        />
      </Box>
    </Box>
  );
}

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    description_id: '',
    data_cria_prodlocal: '',
    lt_nome_prod: '',
    cod_produto: '',
    subitem: '',
    nome_subitem: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleClick = async () =>{
    let data_to_post ={
      "description_id": values.description_id,
      "data_cria_prodlocal": values.data,
      "lt_nome_prod": values.lt_nome_prod,
      "cod_produto": values.cod_produto,
      "subitem": values.subitem,
      "nome_subitem": values.nome_subitem
    }
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      const promise = await axios.post("/api/products/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise.data)
      alert("Produto adiconado com sucesso!")  
      window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adicionar novo produto"
          title="Produto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique o nome do produto"
                label="Nome"
                name="lt_nome_prod"
                onChange={handleChange}
                required
                // value={productData.lt_nome_prod}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Especifique o nome do produto"
                label="Identificador Único"
                name="description_id"
                onChange={handleChange}
                required
                // value={productData.lt_nome_prod}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Código do produto"
                label="Código"
                name="cod_produto"
                onChange={handleChange}
                required
                // value={productData.cod_produto}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Data (AAAA/M)"
                name="data_cria_prodlocal"
                onChange={handleChange}
                required
                // value={productData.data_cria_prodlocal}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subitem"
                name="subitem"
                onChange={handleChange}
                required
                // value={productData.subitem}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Nome do Subitem"
                name="nome_subitem"
                onChange={handleChange}
                required
                // value={productData.nome_subitem}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;

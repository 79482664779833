import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ModalForm from './ModalForm.js'
import EditIcon from '@material-ui/icons/Edit';
import axios from "axios";
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Fonte: https://www.positronx.io/create-react-modal-popup-with-material-ui/
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ProductEditModal({product_id, ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [productData, setProductData] = useState({
        name: '',
        description: '',
        item: '',
        subitem: '',
        group: '',
        subgroup: '',
        link: '',
        price: ''
      })

    const getProductInfo = async () =>{
        const promise = await axios.get("/api/items_description/"+product_id+"/")
        const status = promise.status;
        if(status===200){
            setProductData(promise.data)
        }
    }
    const handleOpen = () => {
        getProductInfo()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<EditIcon />}
                >
                    Editar
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                    <ModalForm productData={productData} setProductData={setProductData} product_id={product_id}></ModalForm>                        
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
import React, { useState } from "react";
import clsx from "clsx";
import Paper from '@material-ui/core/Paper';
import CheckboxLabels from './LikeDislike.js'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Button,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles({
  table: {
    minWidth: 1200,
  },
});


export default function ModalTable({ impressions, ...rest }) {
  const classes = useStyles();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const applyPagination = (impressions) => {
    return impressions.slice(page * limit, page * limit + limit);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* metadados, fonte, preço cidade e estado, campo e feedback */}
            <TableCell>Nome</TableCell>
            <TableCell align="right">Preço</TableCell>
            <TableCell align="right">Fonte</TableCell>
            <TableCell align="right">Data de Coleta</TableCell>
            <TableCell align="right">URL do Produto</TableCell>
            <TableCell align="center">Feedback</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {applyPagination(impressions, limit, page).map((impression) => (
            <TableRow key={impression.id}>
              <TableCell component="th" scope="row">
                {impression.name}
              </TableCell>
              <TableCell align="right">{impression.price}</TableCell>
              <TableCell align="right">{impression.place_name}</TableCell>
              <TableCell align="right">{impression.page_collected}</TableCell>
              <TableCell align="right">{<a href={impression.product_url} target="_blank">Link</a>}</TableCell>
              <TableCell align="right">
                    <CheckboxLabels></CheckboxLabels>
                    
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={impressions.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[1, 2, 3, 4, 5]}
      />
    </Card>
  );
}

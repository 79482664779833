import React, {useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Password from './test'
import FontDetails from './FontDetails';
import Toolbar from './Toolbar'
import Results from './Results'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FontsView = () => {
  const classes = useStyles();

  const [fonts, setFonts] = useState([]);
  const [selectedFontIds, setSelectedFontIds] = useState([]);

  return (
    <Page
      className={classes.root}
      title="Fontes"
    >
      <Container maxWidth={false}>
        <Toolbar
          fonts={fonts}
          setFonts={setFonts}
          selectedFontIds={selectedFontIds}
        />
        <Box mt={3}>
          <Results
            fonts={fonts}
            selectedFontIds={selectedFontIds}
            setSelectedFontIds={setSelectedFontIds}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default FontsView;

import React, { useState } from 'react';
import clsx from 'clsx';
import axios from "axios";
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import AnimatedModal from "./AnimatedModal"
import FontEditModal from "./FontEditModal"
import {
  Avatar,
  Box,
  Card,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, fonts, selectedFontIds, setSelectedFontIds, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [itemDescription, setItemDescription] = useState([]);

  const handleSelectAll = (event) => {
    let newSelectedProductIds;

    if (event.target.checked) {
      newSelectedProductIds = fonts.map((font) => font.id);
    } else {
      newSelectedProductIds = [];
    }

    setSelectedFontIds(newSelectedProductIds);
    console.log("produtos selecionados: ", selectedFontIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFontIds.indexOf(id);
    let newSelectedProductIds = [];

    if (selectedIndex === -1) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedFontIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedFontIds.slice(1)
      );
    } else if (selectedIndex === selectedFontIds.length - 1) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedFontIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedFontIds.slice(0, selectedIndex),
        selectedFontIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFontIds(newSelectedProductIds);
    console.log("produtos selecionados: ", selectedFontIds)

  };
  const handleClick =  async ()=>{
    // if(e.keyCode === 13){
      // let query_text = e.target.value
      // alert("Texto digitado: " + query_text)
      const promise = await axios.get("/api/items_description")
      const status = promise.status;
      // setfonts([])
      if(status===200){
        console.log(promise)
        var results = []
        for (let i = 0; i < promise.data.length; i++) {
          const element = promise.data[i];
          results.push(element)
        }
      // }
      setItemDescription(results)
  }}

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const applyPagination = (fonts) => {
    return fonts.slice(page * limit, page * limit + limit);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {/* <Button color="primary" variant="contained" onClick={handleClick}>Carregar Dados</Button> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                <Checkbox
                    checked={selectedFontIds.length === fonts.length}
                    color="primary"
                    indeterminate={
                      selectedFontIds.length > 0 &&
                      selectedFontIds.length < fonts.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Endereço Web
                </TableCell>
                <TableCell>
                  Endereço Físico
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  CEP
                </TableCell>
                <TableCell>
                  CNPJ
                </TableCell>
                <TableCell>
                  Latitude
                </TableCell>
                <TableCell>
                  Longitude
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {applyPagination(fonts, limit, page).map((font) => (
                <TableRow
                  hover
                  key={font.id}
                  selected={selectedFontIds.indexOf(font.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedFontIds.indexOf(font.id) !== -1}
                      onChange={(event) => handleSelectOne(event, font.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {font.name}
                  </TableCell>
                  <TableCell>
                    {font.web_address}
                  </TableCell>
                  <TableCell>
                    {font.address}
                  </TableCell>
                  <TableCell>
                    {font.br_state}
                  </TableCell>
                  <TableCell>
                    {font.cep}
                  </TableCell>
                  <TableCell>
                    {font.cnpj}
                  </TableCell>
                  <TableCell>
                    {font.latitude}
                  </TableCell>
                  <TableCell>
                    {font.longitude}
                  </TableCell>
                  <TableCell>
                  <FontEditModal font_id={font.id}></FontEditModal>
                  
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={fonts.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
  // customers: PropTypes.array.isRequired
};

export default Results;

import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlined from '@material-ui/icons/ThumbDownOutlined';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

export default function CheckboxLabels() {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox icon={<ThumbUpOutlined />} checkedIcon={<ThumbUp />}color="secondary" name="checkedUp" />}
        // label="Bom"
      />
        <FormControlLabel
        control={<Checkbox icon={<ThumbDownOutlined />} checkedIcon={<ThumbDown />} name="checkedDown" />}
        // label="Ruim"
      />
    </FormGroup>
  );
}
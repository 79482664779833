import React, { useState } from "react";
import PropTypes, { element } from "prop-types";
import clsx from "clsx";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Dropdown,
  SvgIcon,
  makeStyles,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";

import AddNewFontModal from './AddNewFontModal'
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, fonts, setFonts, selectedFontIds, ...rest}) => {
  const classes = useStyles();
  const [productsToExport, setProductsToExport] = useState([]);

  const handleExportClick = async (e) => {
    let newProductsToExport = fonts.filter((font) =>
      selectedFontIds.includes(font.id)
    );
    setProductsToExport(newProductsToExport);

    const fileName = "precos";
    console.log("newProductsToExport", newProductsToExport);
    const json = JSON.stringify(newProductsToExport);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteClick = async (e) => {
    let productsToDelete = fonts.filter((font) =>
      selectedFontIds.includes(font.id)
    );

    let deletedNames = []
    for (let i = 0; i < productsToDelete.length; i++) {
      const element = productsToDelete[i];
      deletedNames.push(element.name)
      const promise = await axios.delete("/api/fonts/"+element.id)
      
    }

    alert("Produtos deletados com sucesso!")
    console.log(deletedNames)
  }

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      let query_text = e.target.value;
      const promise = await axios.get("/api/fonts/?search=" + query_text);
      const status = promise.status;
      if (status === 200) {
        setFonts(promise.data);
        console.log("olha aqui rapaz", promise.data);
      }
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          className={classes.exportButton}
          onClick={handleExportClick}
          color="primary"
          variant="contained"
        >
          Exportar
        </Button>
        <Button
          className={classes.exportButton}
          onClick={handleDeleteClick}
          color="primary"
          variant="contained"
        >
          Deletar
        </Button>
        <AddNewFontModal></AddNewFontModal>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                onKeyDown={keyPress}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar fonte"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ItemModalForm = ({ className, productData, setProductData, ...rest }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    // aqui é necessário usar o mesmo hook que é utilizado para carregar os dados
    // dessa forma, quando o usuario digita algo novo para atualizar, tudo fica de acordo
    setProductData({
      ...productData,
      [event.target.name]: event.target.value
    });
  };

  const handleClick = async () =>{
    let data_to_post ={
      "name": productData.name,
      "item":productData.item,
      "subitem":productData.subitem,
      "group":productData.group,
      "subgroup":productData.subgroup,
    }
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      // console.log("veja aqui a id", product_id)
      const promise = await axios.post("/api/items_description/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise)
      alert("Produto adiconado com sucesso!")
    //   window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adicionar novo produto"
          title="Produto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique o nome do produto"
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={productData.name}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                onChange={handleChange}
                required
                value={productData.description}
                variant="outlined"
              />
            </Grid> */}
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Item"
                name="item"
                onChange={handleChange}
                required
                value={productData.item}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subitem"
                name="subitem"
                onChange={handleChange}
                required
                value={productData.subitem}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Grupo"
                name="group"
                onChange={handleChange}
                required
                value={productData.group}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subgrupo"
                name="subgroup"
                onChange={handleChange}
                required
                value={productData.subgroup}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ItemModalForm.propTypes = {
  className: PropTypes.string
};

export default ItemModalForm;

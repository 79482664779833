import React , { useState, useEffect }from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalProducts = ({ className, numberOfProducts,...rest }) => {
  const classes = useStyles();


  const [nProducts, setnProducts] = useState()

//   esses nomes estao horrorosos, trocar isso pelo amor de Deus
  const [cadastroData, setCadastroData] = useState()
  const [cadastroWithSiteData, setCadastroWithSiteData] = useState()
  const [crawledData, setCrawledData] = useState()
  const [pairsCoverage, setPairsCoverage] = useState()

  const fetchData = async () => {
    const promise1 = await axios.get("http://localhost:8000/api/stores_products/group_by/")
    setCadastroData(promise1.data)

    const promise2 = await axios.get("http://localhost:8000/api/products_website/group_by/")
    setCadastroWithSiteData(promise2.data)

    const promise3 = await axios.get("http://localhost:8000/api/crawled_products/group_by/")
    setCrawledData(promise3.data)

    const promise4 = await axios.get("http://localhost:8000/api/stores_products/pairs_coverage/")
    setPairsCoverage(promise4.data)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
            <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PARES DESCRIÇÃO-ESTABELECIMENTO NO CADASTRO 
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {cadastroData}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PARES DESCRIÇÃO-ESTABELECIMENTO COM SITE 
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {cadastroWithSiteData}
            </Typography>
          </Grid>
          {/* <Grid item> */}
            {/* <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar> */}
          {/* </Grid> */}
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PARES DESCRIÇÃO-ESTABELECIMENTO COLETADOS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
               {crawledData}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PORCENTAGEM DE PARES COBERTOS PELA COLETA 
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {pairsCoverage}
            </Typography>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          {/* <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            Pares descrição-estabelecimento no cadastro: {cadastroData}
          </Typography> */}
          {/* <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            Pares descrição-estabelecimento com site: {cadastroWithSiteData}
          </Typography> */}
          {/* <Typography style={{display: 'inline-block'}}
            className={classes.differenceValue}
            variant="body2"
          >
            Pares descrição-estabelecimento coletados: {crawledData}
          </Typography> */}
          {/* <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            Porcentagem de pares cobertor pela coleta: {pairsCoverage}
          </Typography> */}
          {/* <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography> */}
        </Box>
      </CardContent>
    </Card>
  );
};

TotalProducts.propTypes = {
  className: PropTypes.string
};

export default TotalProducts;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import { CSVLink} from "react-csv";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
 
const useStyles = makeStyles(() => ({
  root: {}
}));

const ExportProducts = ({ className, ...rest }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const handleClick = async (e) =>{

    try {
      const promise = await axios.get("/api/products/")
      const status = promise.status;
      if(status===200){
        setData(promise.data)
        setData((data) => {//trocar depois, não é a melhor solução
            console.log(data);
            return data;
          });
      }
    } catch (error) {
      alert("Erro de requisição!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Filtre e exporte dados de produtos"
          title="Exportar Dados"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >

            <Button 
                variant="contained"
                color="primary"
                onClick={handleClick}>
                Confirmar
            </Button>
            <Button variant="outlined">
            <CSVLink enclosingCharacter={`'`} data={data} filename={"produtos.csv"}>Exportar</CSVLink>
        </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

ExportProducts.propTypes = {
  className: PropTypes.string
};

export default ExportProducts;

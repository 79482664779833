import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
// csrf https://www.techiediaries.com/django-react-forms-csrf-axios/
// reload components https://www.digitalocean.com/community/tutorials/react-replacing-component-lifecycles-with-useeffect

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const states = [
  {
    value: null,
    label: 'Selecione o Estado'
  },
  {
    value: 'mg',
    label: 'Minas Gerais'
  },
  {
    value: 'rj',
    label: 'Rio de Janeiro'
  },
  {
    value: 'sp',
    label: 'São Paulo'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const FontDetails = ({ className, ...rest }) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    name: '',
    web_address: '',
    address: '',
    br_state: '',
    cep: '',
    latitude: '',
    longitude: '',
    cnpj: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleClick = async () =>{
    let data_to_post ={
      "name": values.name,
      "web_address": values.web_address,
      "address": values.address,
      "br_state": values.br_state,
      "cep": values.cep,
      "latitude": values.latitude,
      "longitude": values.longitude,
      "cnpj": values.cnpj
    }
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      const promise = await axios.post("/api/fonts/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise)
      alert("Fonte adiconada com sucesso!")  
      window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adicionar nova fonte"
          title="Fonte"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique o nome da fonte"
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                // value="Loja xyz"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Endereço na Web"
                name="web_address"
                onChange={handleChange}
                required
                // value="wwww.loja.com"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Endereço físico, caso exista"
                label="Endereço Físico"
                name="address"
                onChange={handleChange}
                required
                // value="Av. Mendes Pimentel, n1234"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecionar Estado"
                name="br_state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CEP"
                name="cep"
                onChange={handleChange}
                required
                // value="42313109"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CNPJ"
                name="cnpj"
                onChange={handleChange}
                // type="number"
                // value="xxxxxxxx"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Latitude"
                name="latitude"
                onChange={handleChange}
                type="latitude"
                // value={values.price}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Longitude"
                name="longitude"
                onChange={handleChange}
                type="number"
                // value={values.price}
                variant="outlined"
              />
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

FontDetails.propTypes = {
  className: PropTypes.string
};

export default FontDetails;

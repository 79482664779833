import React, { useState, useEffect, useReducer } from "react";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from "axios";
import ModalTable from './ModalTable.js'
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Fonte: https://www.positronx.io/create-react-modal-popup-with-material-ui/
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ImpressionsModal({ productId, ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [impressions, setImpressions] = React.useState([])
    
    const fetchData = async() =>{
        const promise = await axios.get("/api/products/"+productId+"/impressions")
        setImpressions(promise.data)
        console.log("impressoes", impressions)
    }

    const handleOpen = () => {
        setOpen(true);
        fetchData()
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Button variant="contained" color="secondary" onClick={handleOpen}>
                Ver Mais
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ModalTable impressions={impressions}></ModalTable>                        
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
// import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PriceListView = () => {
  const classes = useStyles();
  // const [customers] = useState(data);
  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  return (
    <Page
      className={classes.root}
      title="Preços"
    >
      <Container maxWidth={false}>
        <Toolbar products={products} 
                 setProducts={setProducts}
                 selectedProductIds={selectedProductIds} />
        <Box mt={3}>
          <Results products={products} 
                   selectedProductIds={selectedProductIds}
                   setSelectedProductIds={setSelectedProductIds} />
        </Box>
      </Container>
    </Page>
  );
};

export default PriceListView;

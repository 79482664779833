import React, { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import clsx from 'clsx';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Dropdown,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import AddNewProductModal from './AddNewProductModal'
// import AreaDropdown from './Dropdown';
// import FormattedInputs from './PriceSelection'

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));



const Toolbar = ({ className, products, setProducts, selectedProductIds, ...rest }) => {

  const classes = useStyles();
  const [productsToExport, setProductsToExport] = useState([])

  const handleExportClick = async (e) =>{

    let newProductsToExport = products.filter(product => selectedProductIds.includes(product.description_id));
    setProductsToExport(newProductsToExport)

    const fileName = "precos";
    console.log("newProductsToExport", newProductsToExport)
    const json = JSON.stringify(newProductsToExport);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const keyPress =  async (e)=>{
    if(e.keyCode === 13){
      let query_text = e.target.value
      const promise = await axios.get("/api/products/?search="+query_text)
      const status = promise.status;
      // setProducts([])
      if(status===200){
        // console.log(promise)
        // var results = []
        // for (let i = 0; i < promise.data.length; i++) {
        //   const element = promise.data[i];
        //   results.push(element)
        // }
        setProducts(promise.data)
      }
  }}

  const handleDeleteClick = async (e) => {
    let productsToDelete = products.filter((product) =>
      selectedProductIds.includes(product.description_id)
    );

    let deletedNames = []
    for (let i = 0; i < productsToDelete.length; i++) {
      const element = productsToDelete[i];
      deletedNames.push(element.name)
      const promise = await axios.delete("/api/products/"+element.description_id)
      
    }

    alert("Produtos deletados com sucesso!")
    console.log(deletedNames)
  }


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button className={classes.exportButton} 
                onClick={handleExportClick}
                color="primary"
                variant="contained">
          Exportar
        </Button>
        <Button
          className={classes.exportButton}
          onClick={handleDeleteClick}
          color="primary"
          variant="contained"
        >
          Deletar
        </Button>
        <AddNewProductModal></AddNewProductModal>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                onKeyDown={keyPress}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Buscar produto"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ModalForm = ({ className, productData, setProductData, product_id, ...rest }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    // aqui é necessário usar o mesmo hook que é utilizado para carregar os dados
    // dessa forma, quando o usuario digita algo novo para atualizar, tudo fica de acordo
    setProductData({
      ...productData,
      [event.target.name]: event.target.value
    });
    console.log("aaaaa", productData)
  };

  const handleClick = async () =>{
    let data_to_post ={
      "description_id":productData.description_id,
      "data_cria_prodlocal":productData.data_cria_prodlocal,
      "lt_nome_prod":productData.lt_nome_prod,
      "cod_produto":productData.cod_produto,
      "subitem":productData.subitem,
      "nome_subitem": productData.nome_subitem
    }
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      const promise = await axios.put("/api/products/"+productData.description_id+"/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise)
      alert("Produto adiconado com sucesso!")  
    //   window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Atualizar produto"
          title="Produto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique o nome do produto"
                label="Nome"
                name="lt_nome_prod"
                onChange={handleChange}
                required
                value={productData.lt_nome_prod}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Código do produto"
                label="Código"
                name="cod_produto"
                onChange={handleChange}
                required
                value={productData.cod_produto}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Data (AAAA/M)"
                name="data_cria_prodlocal"
                onChange={handleChange}
                required
                value={productData.data_cria_prodlocal}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subitem"
                name="subitem"
                onChange={handleChange}
                required
                value={productData.subitem}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Nome do Subitem"
                name="nome_subitem"
                onChange={handleChange}
                required
                value={productData.nome_subitem}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Grupo"
                name="group"
                onChange={handleChange}
                required
                value={productData.group}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subgrupo"
                name="subgroup"
                onChange={handleChange}
                required
                value={productData.subgroup}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Preço"
                name="price"
                onChange={handleChange}
                // type="number"
                value={productData.price}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={productData.country}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecionar Estado"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={productData.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ModalForm.propTypes = {
  className: PropTypes.string
};

export default ModalForm;

import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import ProductsView from "src/views/products/ProductsView";
import PriceListView from "src/views/price/PriceListView";
import DashboardView from "src/views/reports/DashboardView";
import AssociationsView from "src/views/associations/AssociationsView/";
import NotFoundView from "src/views/errors/NotFoundView";
import DescriptionListView from "src/views/description/DescriptionListView";
import RegisterView from "src/views/auth/RegisterView";
import FontsView from "src/views/fonts/FontsView";
import LoginView from "src/views/auth/LoginView";
import LabellingView from "src/views/labelling/LabellingView";
import StartLabellingView from "src/views/labelling/StartLabellingView";

// https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6

const routes = (isLoggedIn) => [
  {
    path: "app",
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: "add_products", element: <ProductsView /> },
      { path: "prices", element: <PriceListView /> },
      { path: "descriptions", element: <DescriptionListView /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "add_fonts", element: <FontsView /> },
      { path: "associations", element: <AssociationsView /> },
      { path: "register", element: <RegisterView /> },
      { path: "labelling", element: <StartLabellingView />,},
      { path: "labelling/start", element: <LabellingView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      {
        path: "404",
        element: isLoggedIn ? <NotFoundView /> : <Navigate to="/login" />,
      },
      { path: "/", element: <Navigate to="/login" /> }, //antes: app/prices
      {
        path: "*",
        element: isLoggedIn ? <Navigate to="/404" /> : <Navigate to="/login" />,
      },
    ],
  },
];

export default routes;

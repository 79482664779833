import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import OpenImageModal from "./OpenImageModal.js";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    width: "600px",
    height: "600px",
  paddingTop: '45.0%', // 16:9,
  marginTop:'30'
  },
});

export default function ModalImageCard({imageUrl, ...rest}) {
  const classes = useStyles();

  const expandImage = (e) =>{
    console.log("expandir imagem")
  }

  return (
    <Card className={classes.root} onClick={expandImage}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageUrl}
          // title="Contemplative Reptile"
        />
        <CardContent>
          {/* <Typography gutterBottom variant="h2" component="h2">
            Produto
          </Typography> */}
          {/* <Typography variant="body2" color="textPrimary" component="h3">
            Nome: Arroz Branco Longo-fino Tipo 1 Camil 5kg
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Estabelecimento: Carrefour
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Preço: R$ 25,00
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Data: 17/02/2021
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Website: carrefour.com.br
          </Typography>

          <Typography gutterBottom variant="h2" component="h2">
            Descrição
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Descrição descrição descrição descrição descrição descrição descrição descrição
          </Typography> */}
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <OpenImageModal imageUrl={imageUrl}></OpenImageModal>
      </CardActions> */}
    </Card>
  );
}

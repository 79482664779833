import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    description: '',
    item: '',
    subitem: '',
    group: '',
    subgroup: '',
    link: '',
    price: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleClick = async () =>{
    let data_to_post ={
      "name": values.name,
      "description":values.description,
      "item":values.item,
      "subitem":values.subitem,
      "group":values.group,
      "subgroup":values.subgroup,
      "link": values.link,
      "price":values.price
    }
    axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

    try {
      const promise = await axios.post("/api/products/",data_to_post)
      const status = promise.status;
      // setProducts([])
      // if(status===200){
      console.log(promise)
      alert("Produto adiconado com sucesso!")  
      window.location.reload();
    } catch (error) {
      alert("Não esqueça de preencher todos os campos!")
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
}

  return (
    <form
      autoComplete="on"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adicionar novo produto"
          title="Produto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique o nome do produto"
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                // value="Abacaxi"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                onChange={handleChange}
                required
                // value="lorem ipsum"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Item"
                name="item"
                onChange={handleChange}
                required
                // value="Item"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subitem"
                name="subitem"
                onChange={handleChange}
                required
                // value="Subitem"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Grupo"
                name="group"
                onChange={handleChange}
                required
                // value="Grupo"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Subgrupo"
                name="subgroup"
                onChange={handleChange}
                required
                // value="Subgrupo"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Preço"
                name="price"
                onChange={handleChange}
                type="number"
                // value={values.price}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Link"
                name="link"
                onChange={handleChange}
                helperText="Especifique o link de onde o produto foi coletado"
                // type="number"
                // value={values.price}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecionar Estado"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;

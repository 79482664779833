import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MetadataList({metaData,...rest}) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>

        <ListItemText primary={"Nome: "+ metaData.name}/>
      </ListItem>
      <ListItem>

        <ListItemText primary={"Loja: "+ metaData.placeName}  />
      </ListItem>

      {/* <ListItem>

        <ListItemText primary={"Metadados: "+metaData.metadata} secondary="July 20, 2014" />
      </ListItem>
       */}
      <ListItem>
      <a href={metaData.productUrl} target="_blank">Link do Produto</a>
        {/* <ListItemText primary={metaData.placeUrl+metaData.productUrl}  /> */}
      </ListItem>
    </List>
  );
}

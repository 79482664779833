import React , { useState, useEffect }from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalProducts = ({ className, numberOfProducts,...rest }) => {
  const classes = useStyles();


  const [nProducts, setnProducts] = useState()

//   esses nomes estao horrorosos, trocar isso pelo amor de Deus
  const [descriptionCadastroData, setDescriptionCadastroData] = useState()
  const [uniqueDescriptionsWithSiteData, setUniqueDescriptionsWithSiteData] = useState()
  const [crawledDescriptionsData, setCrawledDescriptionsData] = useState()

  const fetchData = async () => {
    const promise1 = await axios.get("http://localhost:8000/api/stores_products/registered_places/")
    setDescriptionCadastroData(promise1.data)

    const promise2 = await axios.get("http://localhost:8000/api/products_website/unique_places/")
    setUniqueDescriptionsWithSiteData(promise2.data)

    const promise3 = await axios.get("http://localhost:8000/api/merged_products/places_crawled/")
    setCrawledDescriptionsData(promise3.data)

  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
            <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ESTABELECIMENTOS NO CADASTRO 
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {descriptionCadastroData}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ESTABELECIMENTOS ÚNICOS COM SITE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {uniqueDescriptionsWithSiteData}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ESTABELECIMENTOS COBERTOS PELA COLETA
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {crawledDescriptionsData}
            </Typography>
          </Grid>
          {/* <Grid item> */}
            {/* <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar> */}
          {/* </Grid> */}
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PORCENTAGEM DE ESTABELECIMENTOS CADASTRADOS COBERTOS PELA COLETA 
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {(100*crawledDescriptionsData/descriptionCadastroData).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
        </Box>
      </CardContent>
    </Card>
  );
};

TotalProducts.propTypes = {
  className: PropTypes.string
};

export default TotalProducts;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LabellingRadioSelect from './LabellingRadioSelect'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({description, subitemName, ...rest}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          //   className={classes.title}
          //   color="textPrimary"
          variant="h4"
          component="h4"
          gutterBottom
        >
          Descrição:
        </Typography>
        <Typography component="h1" variant="h1">
          {description.content}
        </Typography>

        <Typography
          //   className={classes.title}
          //   color="textPrimary"
          variant="h4"
          component="h4"
          gutterBottom
        >
          Nome do Subitem:
        </Typography>
        <Typography component="h1" variant="h1">
          {subitemName}
        </Typography>

      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  );
}

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
// import AnimatedModal from "./AnimatedModal"
// import ProductEditModal from "./ProductEditModal"
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Button,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";
// import CheckboxLabels from "./LikeDislike";
import { Check } from "react-feather";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, products, selectedProductIds, setSelectedProductIds, ...rest }) => {
  const classes = useStyles();
  // const [selectedProductIds, setselectedProductIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedProductIds;

    if (event.target.checked) {
      newSelectedProductIds = products.map((product) => product.id);
    } else {
      newSelectedProductIds = [];
    }

    setSelectedProductIds(newSelectedProductIds);
    console.log("produtos selecionados: ", selectedProductIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedProductIds.indexOf(id);
    let newSelectedProductIds = [];

    if (selectedIndex === -1) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(1)
      );
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
        selectedProductIds.slice(0, selectedIndex),
        selectedProductIds.slice(selectedIndex + 1)
      );
    }

    setSelectedProductIds(newSelectedProductIds);
    console.log("produtos selecionados: ", selectedProductIds)

  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const applyPagination = (products) => {
    return products.slice(page * limit, page * limit + limit);
  };

  const handleEditClick = product_id => () => {
    alert(product_id);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProductIds.length === products.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.length > 0 &&
                      selectedProductIds.length < products.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Nome</TableCell>
                {/* <TableCell>Intervalo de Preço</TableCell> */}
                <TableCell>Subitem</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Grupo</TableCell>
                <TableCell>Subgrupo</TableCell>
                {/* <TableCell>Metadados</TableCell> */}
                {/* <TableCell>Detalhes</TableCell> */}
                {/* <TableCell>Feedback</TableCell>
                <TableCell>Editar</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
            {applyPagination(products, limit, page).map((product) => (
                <TableRow
                  hover
                  key={product.id}
                  selected={selectedProductIds.indexOf(product.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProductIds.indexOf(product.id) !== -1}
                      onChange={(event) => handleSelectOne(event, product.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      {/* <Avatar
                        className={classes.avatar}
                        src={customer.avatarUrl}
                      >
                        {getInitials(customer.name)}
                      </Avatar> */}
                      <Typography color="textPrimary" variant="body1">
                        {product.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  {/* <TableCell> 20-24.2{product.price}</TableCell> */}
                    <TableCell>{product.subitem}</TableCell>
                    <TableCell>{product.item}</TableCell>
                    <TableCell>{product.group}</TableCell>
                    <TableCell>{product.subgroup}</TableCell>
                  {/* <TableCell width="40%">{product.description}</TableCell> */}
                  <TableCell>
                    {/* <AnimatedModal></AnimatedModal> */}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditClick}
                    >
                      Ver Mais
                    </Button> */}
                  </TableCell>
                  {/* <TableCell width="30%">
                    <CheckboxLabels></CheckboxLabels>
                    <TextField
                    fullWidth
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <SvgIcon
                    //         fontSize="small"
                    //         color="action"
                    //       >
                    //         <SearchIcon />
                    //       </SvgIcon>
                    //     </InputAdornment>
                    //   )
                    // }}
                    placeholder="Digite"
                    variant="outlined"
                  />
                  </TableCell> */}
                  {/* <TableCell>
                  <ProductEditModal product_id={product.id}></ProductEditModal>
                  
                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={products.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired,
};

export default Results;
